<template>
  <div>
    <loading-icon v-if="loading" />
    <div class="header-color login" v-else>
      <my-header
        :btnLeft="'mdi-information-outline'"
        @clickBtnLeft="menuAbout = true"
        :title="'כניסה'"
        :btnRight="'mdi-share-variant-outline'"
        @clickBtnRight="showMenuShare"
      />
      <v-container
        class="form-container d-flex justify-center flex-column body-color px-10"
      >
        <v-row justify="space-around" class="mb-10 mt-10">
          <v-img
            class="d-flex justify-center"
            lazy-src="/imgs/admin/login.png"
            max-height="300"
            max-width="177"
            src="/imgs/admin/login.png"
          ></v-img>
        </v-row>

        <form @keyup.enter="submit">
          <v-text-field
            v-model="email"
            label="אימייל"
            required
            prepend-inner-icon="mdi-email"
            autofocus
            @keydown.space.prevent
          >
            <v-icon>mdi-account</v-icon>
          </v-text-field>
          <v-text-field
            v-model="password"
            :append-icon="!show ? 'mdi-eye-off' : 'mdi-eye-outline'"
            :prepend-inner-icon="'mdi-lock'"
            @click:append="prependIconCallback"
            label="סיסמה"
            :type="show ? 'text' : 'password'"
            required
          />
          <v-row justify="center" class="pt-6">
            <v-col cols="10">
              <v-btn
                class="pa-6"
                block
                rounded
                x-large
                outlined
                color="primary"
                @click="submit"
                v-text="'כניסה'"
              />
            </v-col>
          </v-row>

          <v-row justify="center" style="margin-top: -10px">
            <v-col cols="6" class="d-flex justify-center align-center">
              <v-btn text disabled>
                <a @click="openDialog = true" class="darkerGrey--text">
                  שכחת סיסמה?
                </a>
              </v-btn>
            </v-col>
            <v-col cols="6" class="d-flex justify-center align-center">
              <v-btn
                text
                outlined
                @click="gotoRegister"
                class="blue--text pa-4"
                v-text="'הרשמה'"
              />
            </v-col>
          </v-row>
        </form>

        <v-col cols="12" xs="6" sm="6" md="7" v-if="menuShare">
          <v-bottom-sheet v-model="menuShare" max-width="700px">
            <v-sheet max-height="100%" class="py-10">
              <v-row justify="center">
                <v-col cols="2" class="pa-5">
                  <facebook
                    :url="url"
                    scale="3"
                    class="mouse-pointer"
                  ></facebook>
                </v-col>
                <v-col cols="2" class="pa-5">
                  <email class="mouse-pointer" :url="url" scale="3"></email>
                </v-col>
                <v-col cols="2" class="pa-5">
                  <whats-app
                    class="mouse-pointer"
                    :url="url"
                    scale="3"
                  ></whats-app>
                </v-col>
                <v-col cols="2" class="pa-5">
                  <telegram
                    class="mouse-pointer"
                    :url="url"
                    scale="3"
                  ></telegram>
                </v-col>
                <v-col cols="2" class="pa-5">
                  <twitter class="mouse-pointer" :url="url" scale="3"></twitter>
                </v-col>
              </v-row>
            </v-sheet>
          </v-bottom-sheet>
        </v-col>
        <v-col cols="12" xs="6" sm="3" md="3 " v-if="menuAbout">
          <v-bottom-sheet v-model="menuAbout" max-width="300px">
            <About />
          </v-bottom-sheet>
        </v-col>
      </v-container>
      <v-dialog
        v-model="openDialog"
        class="dialog"
        color="primary"
        max-width="700px"
      >
        <v-card class="">
          <v-toolbar flat color="">
            <v-btn icon dark @click="openDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <!-- <v-toolbar-title class="white--text"
            
          </v-toolbar-title> -->
          </v-toolbar>
          <v-container fill-height fluid>
            <v-row align="center" justify="center">
              <v-col cols="8" align="start" justify="start">
                הזן את המייל שלך:
              </v-col>
              <v-col cols="8" align="center" justify="center">
                <v-text-field
                  v-model="email"
                  label="אימייל"
                  required
                  prepend-inner-icon="mdi-email"
                  autofocus
                  @keydown.space.prevent
                >
                  <v-icon>mdi-account</v-icon>
                </v-text-field>
              </v-col>
              <v-col cols="8" align="center" justify="center">
                <v-btn @click="restPassword" color="lightPurple" dark>
                  לאיפס הסיסמה
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import LoadingIcon from "../components/LoadingIcon.vue";
import MyHeader from "../components/MyHeader.vue";
import {
  Facebook,
  Twitter,
  Telegram,
  WhatsApp,
  Email,
} from "vue-socialmedia-share";
import About from "../components/About.vue";
export default {
  name: "Login",
  components: {
    MyHeader,
    LoadingIcon,
    Facebook,
    Twitter,
    Telegram,
    WhatsApp,
    Email,
    About,
  },
  data() {
    return {
      show: false,
      email: null,
      password: null,
      menuShare: false,
      menuAbout: false,
      loading: false,
      openDialog: false,
      url: "https://paykid.co.il/",
    };
  },
  methods: {
    gotoRegister() {
      this.$router.push({ name: "Register" });
    },
    gotoAbout() {
      console.log("About");
    },
    prependIconCallback() {
      this.show = !this.show;
    },
    async submit() {
      this.loading = true;
      try {
        await this.$store.dispatch("auth/login", {
          email: this.email,
          password: this.password,
        });
        this.$store.getters.isAdmin
          ? this.$router.push({ name: "AdminUserManager" })
          : this.$router.push({ name: "Home" });
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.$store.commit("invokeSnack", {
          msg: "שם משתמש או סיסמה שגויים",
          type: "error",
        });
        console.log(e);
      }
    },
    async restPassword() {
      try {
        await this.$store.dispatch("auth/reset-password", {
          email: this.email,
        });
        this.openDialog = false;
      } catch (e) {
        console.log(e);
      }
    },
    showMenuShare() {
      this.menuShare = true;
    },
  },
  watch: {
    menuShare() {
      return console.log(this.menuShare);
    },
  },
};
</script>
